import React, { useState } from 'react'
import {
  selectRule,
  useRulesStore,
} from './store'
import Override from './override'
import AutomationInputControl from './input'
import RuleTitle from './ruleTitle'
import Stack from 'ui/stack'
import AutomationRuleCard from './automation-rule-card'
import Footer from './footer'
import useRuleState from './useRuleState'
import EquipmentSection from './equipment-section'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

const inputMeasurements = [
  'pm25',
  'voc',
  'temperature',
  'humidity',
  'dew_point',
  'epa_aqi',
  'airflow',
]

/**
 * this shit should allow a user to select
 * a measurement, the probably just fire
 * a callback that the parent can deal with
 */
const InputSelector = ({ ruleId }) => {
  const [value, setValue] = useState()

  return (
    <FormControl
      id={`input_select`}
      size='small'
      variant='outlined'
      style={{
        width: '100%',
      }}
    >
      <InputLabel
        id={`input_select`}>Equipment</InputLabel>
      <Select
        id={`input_select`}
        name={`input_select`}
        labelId={`input_select`}
        label='Measurement'
      >
        <MenuItem
          disabled
          value={``}
        >
          Select...
        </MenuItem>
        {inputMeasurements.map(x => (
          <MenuItem
            key={x}
            // disabled={menuItemIsDisabled(output, x)}
            value={x}
          >
            <Box display='flex' flexDirection='column'>
              {x}
              {/* {parseEquipmentType(x.equipment.type)} */}
              {/* <Muted style={{ fontSize: '14px' }}>{x.equipment.brand}</Muted> */}
            </Box>
          </MenuItem>

        ))}
      </Select>
    </FormControl>
  )
}

const Rule = ({ ruleId, setEditing }: { ruleId: number, setEditing: any }) => {
  const rule = useRulesStore(selectRule(ruleId))

  const {
    touched,
    loading,
    success,
    error,
    handleCancel,
    handleSave,
  } = useRuleState(ruleId)

  const _handleCancel = () => {
    setEditing(false)
    handleCancel()
  }

  return (
    <AutomationRuleCard>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Stack direction='row'>
            <RuleTitle>
              Activate
            </RuleTitle>
            <Override
              ruleId={rule.id}
            />
          </Stack>
          <Stack divider>
            <EquipmentSection outputs={rule.outputs} />
          </Stack>
        </Stack>
        {rule.inputs.map((input, index) => (
          <Stack
            key={input.id}
            spacing={1}
          >
            <RuleTitle>
              {(index > 0) ? 'And' : 'When'}
            </RuleTitle>
            <InputSelector
              ruleId={rule.id}
            />
            <AutomationInputControl
              ruleId={rule.id}
              inputId={input.id} />
          </Stack>
        ))}
        <Footer
          touched={touched}
          loading={loading}
          success={success}
          error={error}
          handleCancel={_handleCancel}
          handleSave={handleSave}
        />
      </Stack>
    </AutomationRuleCard>
  )
}

export default Rule
