export type JourneyState =
    "created" |
    "sign_up_stuck" |
    "pup_purchased" |
    "pup_received" |
    "pup_stuck" |
    "pup_install" |
    "customer_install_stuck" |
    "customer_install" |
    "repeat_install_stuck" |
    "repeat_install"

export type Measurement =
    'pm25' |
    'voc' |
    'temperature' |
    'humidity' |
    'dew_point' |
    'epa_aqi' |
    'airflow'

export type SetPoint =
    'celsius' |
    'fahrenheit'


export type ScheduleInterval =
    "always_off" |
    "on_5_off_55" |
    "on_5_off_25" |
    "on_10_off_20" |
    "on_20_off_20" |
    "on_20_off_10" |
    "always_on"

export enum ScheduleIntervals {
    "always_off" = "always_off",
    "on_5_off_55" = "on_5_off_55",
    "on_5_off_25" = "on_5_off_25",
    "on_10_off_20" = "on_10_off_20",
    "on_20_off_20" = "on_20_off_20",
    "on_20_off_10" = "on_20_off_10",
    "always_on" = "always_on",
}

export type AutomationInputType = 'cam_threshold' |
    'outdoor_weather_threshold' |
    'schedule' |
    'cam_range' |
    'outdoor_weather_range' |
    'comfort_score'

export interface AutomationInput {
    id: number
    type: string
    currently_triggered: boolean
    detection_duration: number
    device_id: number | null
    measurement: Measurement
    set_point_unit?: SetPoint
    rising: boolean
    start_set_point: number
    stop_set_point: number
}

export interface AutomationRangeInput {
    id: number
    type: 'cam_range'
    device_id: number | null
    detection_duration: number
    measurement: Measurement
    rising?: boolean
    currently_triggered: boolean
    set_point_unit?: SetPoint
    high_end_start_set_point: number
    high_end_stop_set_point?: number
    low_end_start_set_point: number
    low_end_stop_set_point?: number
    triggered_inside_range?: boolean
}

export interface AutomationSchedule {
    id: number
    type: 'schedule'
    currently_triggered: boolean
    weekday_awake_interval: ScheduleInterval
    weekday_sleep_interval: ScheduleInterval
    weekend_awake_interval: ScheduleInterval
    weekend_sleep_interval: ScheduleInterval
    weekend_start_time: string
    weekend_stop_time: string
    weekday_start_time: string
    weekday_stop_time: string
}

export interface AutomationOutput {
    id: number
    type: string
    enabled: boolean
    device_id: number
    terminal: number
    active_high: boolean
    equipment_id: number
    equipment_component: number
    email?: string
    currently_triggered?: boolean
    equipment?: Equipment
    interlocked_blowers?: number[]
    interlocked_non_blowers?: number[]
}

export interface EmailAlertAutomationOutput {
    id: number
    type: string
    currently_triggered?: boolean
    email?: string
}

export interface AutomationTrigger {
    id: number
    enabled: boolean
    filtration: boolean
    ventilation: boolean
    humidity: boolean
    inputs: number[]
    outputs: number[]
    override_on: boolean
    override_timeout: string | null
    isTemplate?: boolean // client only
}

export type AutomationTemplate = 'none' |
    'everyday_automation' |
    'demand_control_ventilation_1' |
    'demand_control_ventilation_2' |
    'demand_control_ventilation_3'

export interface Interlock {
    id?: number,
    automation_id: number,
    enabled: boolean,
    primary_output: number,
    secondary_output: number,
}

export interface AutomationResponse {
    zone: string
    create_timestamp: string // date string
    dwelling_id: number
    status: string
    active: boolean
    template: AutomationTemplate
    filtration_override_on: boolean
    filtration_override_timeout: string | null // date string
    ventilation_override_on: boolean
    ventilation_override_timeout: string | null // date string
    humidity_override_on: boolean
    humidity_override_timeout: null
    inputs: AutomationInput[]
    outputs: AutomationOutput[]
    rules: AutomationTrigger[]
    interlocks: Interlock[]
}

/**
 * an automation in app state has an ID
 * but an automation returned from the server
 * does not have an ID
 */
export interface Automation extends AutomationResponse {
    id: number
}

export interface EmailAlertAutomation {
    id: number
    zone: string
    create_timestamp: string // date string
    dwelling_id: number
    enabled: boolean
    active: boolean
    template: AutomationTemplate
    filtration_override_on: boolean
    filtration_override_timeout: string | null // date string
    ventilation_override_on: boolean
    ventilation_override_timeout: string | null // date string
    humidity_override_on: boolean
    humidity_override_timeout: null
    inputs: AutomationRangeInput[]
    outputs: EmailAlertAutomationOutput[]
    rules: AutomationTrigger[]
}

interface AutomationLogRule {
    id: number
    inputs: number[]
    outputs: number[]
}

// updated automation log
export interface AutomationLog {
    id: number
    automation_id: string
    start_timestamp: string
    stop_timestamp: string
    rules: AutomationLogRule[]
}

export interface Device {
    cac_device_ids: number[]
    cam_device_id?: number
    dwelling_id: number
    equipment_id?: number
    hub_device_id: string
    hub_id: string
    id: number
    install_timestamp: string
    install_type: string
    name: string
    pcb_serial_number: string
    plastic_serial_number: string
    type: 'cac' | 'cam'
    zone: string
    zone_size: number
}

export interface Equipment {
    id: number
    dwelling_id: number
    name: string
    install_timestamp: string
    type: string
    zone: string
    zone_size: number
    heating: boolean
    cooling: boolean
    duct_type: string
    duct_shape: string
    duct_material: string
    duct_width: number
    duct_height: number
    normally_closed: boolean
    brand?: string
    model?: string
    // special prop to identify which
    // equipment the monitor is installed on
    // not part of the model
    airflowEquipment?: boolean
}

export const EquipmentTypes = {
    CENTRAL_AIR_HANDLER: 'central_air_handler',
    MECHANICAL_VENTILATION: 'mechanical_ventilation',
    HUMIDIFIER: 'humidifier',
    DEHUMIDIFIER: 'dehumidifier',
    OTHER: 'other',
    VENTILATING_DEHUMIDIFIER: 'ventilating_dehumidifier',
    OUTDOOR_AIR_DAMPER: 'outdoor_air_damper',
    NUM: 7,
}

export const EquipmentTypesNurmeric = {
    CENTRAL_AIR_HANDLER: 0,
    MECHANICAL_VENTILATION: 1,
    HUMIDIFIER: 2,
    DEHUMIDIFIER: 3,
    OTHER: 4,
    VENTILATING_DEHUMIDIFIER: 5,
    OUTDOOR_AIR_DAMPER: 6,
    NUM: 7,
}

export type InputOrSchedule = AutomationInput | AutomationSchedule

export interface AutomationRule {
    id: number
    inputs: any[]
    zone: string
    trigger: AutomationTrigger
    outputs: AutomationOutput[]
    automationId: number
    dwellingId: number
}

export interface AutomationScheduleRule {
    id: number
    inputs: AutomationSchedule[]
    zone: string
    trigger: AutomationTrigger
    outputs: AutomationOutput[]
    automationId: number
    dwellingId: number
}

export interface RuleRefs {
    id: number
    inputs: number[]
    trigger: number
    outputs: number[]
    automationId: number
    dwellingId: number
}

export interface AutomationRuleGroup {
    id: number
    sharedInputs: number[]
    rules: RuleRefs[]
}

export interface ScheduleRule {
    id: number
    inputs: AutomationSchedule[]
    trigger: AutomationTrigger
    outputs: AutomationOutput[]
    automationId: number
    dwellingId: number
}

export interface PillarRules {
    pillar: AutomationPillar
    outputGroups: Array<AutomationOutput[]> // outputs by rule
    inputs: AutomationSchedule[]
    trigger: AutomationTrigger
    outputs: AutomationOutput[]
}

export type AutomationPillar = 'humidity' | 'ventilation' | 'filtration'

export interface MonitorTelemetry {
    timestamp: string;
    airflow: number;
    voc_mc: number;
    voc_count: number;
    pm_count: number;
    pm_mc: number;
    temperature: number;
    humidity: number;
    voc_status: string;
    pm_status: string;
    combined_status: string;
    highest_factor: string;
}
