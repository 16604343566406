import { DefaultAutomation, defaultFiltration, defaultLowHumidity, defaultOutdoorAirInput, defaultVentilation } from "features/customer-drill-down/equipment/install/data"
import { DuctState, FilterState } from "features/customer-drill-down/equipment/install/duct-information"
import { setAutomation, setAutomations, setEquipment } from "features/customer-drill-down/equipment/store"
import { tempId } from "features/customer-drill-down/equipment/util"
import httpService from "state-mngt/services/data/http-service"
import {
    Automation,
    AutomationOutput,
    AutomationRule,
    Equipment,
    EquipmentTypes,
    Interlock,
} from "types"
import { create } from "zustand"
import { findCompressor, findEquipmentComponent, findEquipmentType, findFan } from "./util"

type PartialOutput = Partial<AutomationOutput>
type PartialRule = Partial<AutomationRule>

interface Install {
    outputs: PartialOutput[]
    rules: PartialRule[]
    interlocks: Interlock[]
    equipment: Partial<Equipment>[]
}

export type DuctFilterStateKey = 'monitor' | 'c1r1' | 'c1r2' | 'c2r1' | 'c2r2'

export interface InstallStore {
    step: number
    dwellingId: number
    zone: string
    zoneSize: number
    data: { [key: number]: any }
    install: Install
}

/**
 * ends up with a bunch of rules, inputs, outputs
 */
const createDefaultAutomationRules = (dwelling_id: number, equipment: any[], outputs: any[]) => {
    const airHandler = equipment.find(x => x.type === EquipmentTypes.CENTRAL_AIR_HANDLER)
    const mechanicalVentilation = equipment.find(x => x.type === EquipmentTypes.MECHANICAL_VENTILATION)
    const ventilatingDehumidifierFan = findFan(
        outputs,
        equipment.find(x =>
            x.type === EquipmentTypes.VENTILATING_DEHUMIDIFIER))
    const ventilatingDehumidifierCompressor = findCompressor(
        outputs,
        equipment.find(x =>
            x.type === EquipmentTypes.VENTILATING_DEHUMIDIFIER))
    const outdoorDamper = equipment.find(x => x.type === EquipmentTypes.OUTDOOR_AIR_DAMPER)
    const humidifier = equipment.find(x => x.type === EquipmentTypes.HUMIDIFIER)

    let ventilationAutomation: any = null
    let filtrationAutomation: any = null
    let highHumidityAutomation: any = null
    let lowHumidityAutomation: any = null

    const equipmentAutomation: Automation = {
        id: tempId(),
        dwelling_id,
        status: '',
        active: true,
        template: 'none',
        create_timestamp: '',
        filtration_override_on: false,
        filtration_override_timeout: null,
        ventilation_override_on: false,
        ventilation_override_timeout: null,
        humidity_override_on: false,
        humidity_override_timeout: null,
        zone: equipment[0].zone,
        outputs,
        inputs: [],
        rules: [],
        interlocks: [],
    }

    if (airHandler) {
        const ventilationDefaults = defaultVentilation(outputs.find(x => x.equipment_id === airHandler.id))
        equipmentAutomation.inputs = [...(equipmentAutomation.inputs || []), ...ventilationDefaults.inputs]

        filtrationAutomation = defaultFiltration(outputs.find(x => x.equipment_id === airHandler.id))
    } else if (ventilatingDehumidifierFan) {
        ventilationAutomation = defaultVentilation(outputs.find(x => x.equipment_id === ventilatingDehumidifierFan.id))
        filtrationAutomation = defaultFiltration(outputs.find(x => x.equipment_id === ventilatingDehumidifierFan.id))
    }

    if (mechanicalVentilation && ventilationAutomation) {
        const output = outputs.find(x => x.equipment_id === mechanicalVentilation.id)
        if (output) {
            ventilationAutomation = {
                ...ventilationAutomation,
                outputs: [
                    ...ventilationAutomation.outputs,
                    output,
                ],
                rules: [{
                    ...ventilationAutomation.rules[0],
                    outputs: [...ventilationAutomation.rules[0].outputs, output.id],
                }],
            }
        }
    }

    if (outdoorDamper && ventilationAutomation) {
        const output = outputs.find(x => x.equipment_id === outdoorDamper.id)
        if (output) {
            ventilationAutomation = {
                ...ventilationAutomation,
            }
        }
    }

    if (humidifier) {
        lowHumidityAutomation = defaultLowHumidity(outputs.find(x => x.equipment_id === humidifier.id))
    }

    // const automations: { [key: number]: Automation } = [
    //     ventilationAutomation,
    //     filtrationAutomation,
    //     highHumidityAutomation,
    //     lowHumidityAutomation,
    // ]
    //     .filter(x => x)
    //     .map(x => ({
    //         ...x,
    //         id: tempId(),
    //         dwelling_id,
    //         zone: equipment[0].zone,
    //     }))
    //     .reduce((prev, curr) => ({
    //         ...prev,
    //         [curr.id]: curr,
    //     }), {})

    // console.log('Default automations', automations)
    setAutomations({ [equipmentAutomation.id]: equipmentAutomation })
}

const defaultControllers = {
    relay1: {
        equipment: 'Select...',
        normally_closed: true,
        brand: '',
        model: '',
    },
    relay2: {
        equipment: 'Select...',
        normally_closed: true,
        brand: '',
        model: '',
    },
}

const useStore = create<InstallStore>((set, get) => ({
    step: 0,
    zone: '',
    zoneSize: 1000,
    dwellingId: 0,
    data: {
        0: {
            installType: 'residential',
            deploymentType: 'sale-to-homeowner',
            isValid: false,
            error: '',
            proceed: () => {
                const { zone } = get()
                if (zone) {
                    // set the zone on the URL, the application uses the zone query param
                    const url = new URL(window.location.href)
                    url.searchParams.set('zone', zone)
                    const updatedUrl = url.toString()
                    window.history.pushState({}, '', updatedUrl)
                }
                if (get()?.data?.[0]?.isValid) set({ step: 1 })
            },
        },
        1: {
            monitor: {
                equipment: '',
                brand: '',
                model: '',
            },
            controllers: {
                0: defaultControllers,
                1: defaultControllers,
            },
            ducts: {
                'monitor': null,
                'c1r1': null,
                'c1r2': null,
                'c2r1': null,
                'c2r2': null,
            },
            filters: {
                'monitor': null,
                'c1r1': null,
                'c1r2': null,
                'c2r1': null,
                'c2r2': null,
            },
            isValid: false,
            error: '',
            isLoading: false,
            proceed: async () => {
                const {
                    isValid,
                } = get().data[1]

                if (!isValid) return setStepData(1)({ error: 'Incomplete field(s)' })

                setStepData(1)({ isLoading: true })

                const {
                    monitor,
                    controllers,
                    filters,
                    ducts,
                } = get().data[1]

                const {
                    dwellingId,
                    zoneSize,
                    zone,
                } = get()

                const blowerIsVentilatingDehumidifier = monitor.equipment.includes('ventilating_dehumidifier')

                const controllerHasVentilatingDehumidifierCompressor = [
                    controllers[0].relay1.equipment,
                    controllers[0].relay2.equipment,
                    controllers[1].relay1.equipment,
                    controllers[1].relay2.equipment,
                ].includes('ventilating_dehumidifier_compressor')

                const hasSharedVentilatingDehumifierEquipment = blowerIsVentilatingDehumidifier && controllerHasVentilatingDehumidifierCompressor
                const isVentilatingDehumidifier = equip => hasSharedVentilatingDehumifierEquipment && equip.includes('ventilating_dehumidifier')

                let ventilatingDehumeTempId = tempId()

                const equipmentSelection: { equipment: string, brand: string, tempId: number, terminal?: number, domain: string }[] = [
                    {
                        ...monitor,
                        ...(ducts.monitor || {}),
                        domain: 'monitor',
                    },
                    {
                        ...controllers[0].relay1,
                        terminal: 1,
                        ...(ducts.c1r1 || {}),
                        domain: 'c1r1',
                    },
                    {
                        ...controllers[0].relay2,
                        terminal: 2,
                        ...(ducts.c1r2 || {}),
                        domain: 'c1r2',
                    },
                    {
                        ...controllers[1].relay1,
                        terminal: 1,
                        ...(ducts.c2r1 || {}),
                        domain: 'c2r1',
                    },
                    {
                        ...controllers[1].relay2,
                        terminal: 2,
                        ...(ducts.c2r2 || {}),
                        domain: 'c2r2',
                    },
                ]
                    .filter(x => x.equipment && (x.equipment !== 'Select...'))
                    .map(x => ({
                        ...x,
                        tempId: isVentilatingDehumidifier(x.equipment) ? ventilatingDehumeTempId : tempId(),
                    }))

                /**
                 * do not create the same equipment twice.
                 * if there is a ventilating dehumidifier
                 * fan and compressor, that is one equipment.
                 * 
                 * outputs need to be created from the selection
                 * but then the equipment itself needs to filter
                 * out duplicates
                 * 
                 * THEN. once the equipment is saved, we must find
                 * the saved equipment ID and save it to the correct
                 * output.
                 */

                let serverRequestEquipment: any[]

                let resultingOutputs: Partial<AutomationOutput> & { tempId: number }[] = equipmentSelection.map((x, i, self) => ({
                    ...x,
                    equipment_component: findEquipmentComponent(x.equipment),
                    id: -(i + 1),
                    terminal: x.terminal,
                    type: 'cac',
                })).filter(x => x.domain !== 'monitor')

                /**
                 * for each output we need an equipment
                 */

                if (hasSharedVentilatingDehumifierEquipment) {
                    serverRequestEquipment = equipmentSelection
                        .filter(x => x.equipment !== 'ventilating_dehumidifier_compressor')
                        .map(({ brand, equipment, ...rest }) => ({
                            type: findEquipmentType(equipment),
                            brand,
                            'dwelling_id': dwellingId,
                            zone,
                            zone_size: zoneSize,
                            ...rest
                        }))
                } else {
                    serverRequestEquipment = equipmentSelection.map(({ brand, equipment, ...rest }) => ({
                        type: findEquipmentType(equipment),
                        brand,
                        'dwelling_id': dwellingId,
                        zone,
                        zone_size: zoneSize,
                        ...rest
                    }))
                }

                let savedEquipments

                try {
                    // create the equipments
                    savedEquipments = await Promise.all<{ id: number }>(
                        serverRequestEquipment.map(x =>
                            httpService.post(`/equipment`, x),
                        ),
                    )

                    savedEquipments = savedEquipments.map((x, i) => ({
                        ...serverRequestEquipment[i],
                        id: x.id,
                    }))
                } catch (e) {
                    // @ts-ignore
                    return setStepData(1)({ error: e?.message || 'Couldn\'t save equipment. Please try again. If the issue persists please contact support.', isLoading: false })
                }

                const idMap = savedEquipments.reduce((prev, curr) => ({ ...prev, [curr.tempId]: curr.id }), {})

                resultingOutputs = resultingOutputs.map((x, i) => ({
                    ...x,
                    equipment_id: idMap[x.tempId],
                }))

                setEquipment(savedEquipments.reduce(((prev, curr) => ({
                    ...prev,
                    [curr.id]: curr,
                })), {}))

                const _filters = [
                    { ...filters.monitor, ...(filters.monitor ? { equipment_id: savedEquipments.find(x => x.domain === 'monitor')?.id } : {}) },
                    { ...filters.c1r1, ...(filters.c1r1 ? { equipment_id: savedEquipments.find(x => x.domain === 'c1r1')?.id } : {}) },
                    { ...filters.c1r2, ...(filters.c1r2 ? { equipment_id: savedEquipments.find(x => x.domain === 'c1r2')?.id } : {}) },
                    { ...filters.c2r1, ...(filters.c2r1 ? { equipment_id: savedEquipments.find(x => x.domain === 'c2r1')?.id } : {}) },
                    { ...filters.c2r2, ...(filters.c2r2 ? { equipment_id: savedEquipments.find(x => x.domain === 'c2r2')?.id } : {}) },
                ].filter(x => x && x.equipment_id)

                try {
                    await Promise.all(_filters.map(x => httpService.post(`/filter`, x)))
                } catch (e) {
                    // @ts-ignore
                    return setStepData(1)({ error: e?.message || 'Couldn\'t save equipment. Please try again. If the issue persists please contact support.', isLoading: false })
                }

                const step = (savedEquipments.length === 1) ? 2 : 2 // skip the other steps if they only have a blower
                const outputs = resultingOutputs.map(({ tempId, ...rest }) => ({ ...rest }))
                createDefaultAutomationRules(dwellingId, savedEquipments, outputs)

                set(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        1: { ...prev.data[1], isLoading: false },
                    },
                    step,
                    install: {
                        ...prev.install,
                        outputs,
                        equipment: savedEquipments,
                    },
                }))
            },
        },
        2: {
            isValid: true,
            error: '',
            proceed: async () => {
                set({ step: 3 })
                // if (get()?.data?.[2]?.isValid) set({ step: 3 })
            },
        },
        3: {
            isValid: false,
            error: '',
            proceed: async () => {
                // if (get()?.data?.[2]?.isValid) set({ step: 3 })
            },
        },
    },
    install: {
        inputs: [],
        outputs: [],
        rules: [],
        interlocks: [],
        equipment: [],
    },
}))

const setStepData = (step: number) => (data: {}) =>
    useStore.setState(prev => ({
        ...prev,
        data: {
            ...prev.data,
            [step]: {
                ...prev.data[step],
                ...data,
            },
        },
    }))

const setMonitorData = monitorData => {
    useStore.setState(prev => ({
        ...prev,
        data: {
            ...prev.data,
            1: {
                ...prev.data[1],
                monitor: {
                    ...prev.data[1].monitor,
                    ...monitorData,
                },
            },
        },
    }))
}

const setController = (controller: 0 | 1) => (data: { relay1: {}, relay2: {} }) =>
    useStore.setState(prev => ({
        ...prev,
        data: {
            ...prev.data,
            1: {
                ...prev.data[1],
                controllers: {
                    ...prev.data[1].controllers,
                    [controller]: data,
                },
            },
        },
    }))

const setIsValid = (step: number) => (isValid: boolean) =>
    useStore.setState(prev => ({
        ...prev,
        data: {
            ...prev.data,
            [step]: {
                ...(prev.data[step] || {}),
                isValid,
            },
        },
    }))

const setStep = (step: number) =>
    useStore.setState(prev => ({
        ...prev,
        step,
    }))

const setPrevStep = () =>
    useStore.setState(prev => ({
        ...prev,
        step: Math.max(prev.step - 1, 0),
    }))

const setDuctData = (key: DuctFilterStateKey) => (data: Partial<DuctState>) =>
    useStore.setState(prev => ({
        ...prev,
        data: {
            ...prev.data,
            1: {
                ...prev.data[1],
                ducts: {
                    ...prev.data[1].ducts,
                    [key]: {
                        ...prev.data[1].ducts[key],
                        ...data,
                    },
                },
            },
        },
    }))

const setFilterData = (key: DuctFilterStateKey) => (data: Partial<FilterState>) =>
    useStore.setState(prev => ({
        ...prev,
        data: {
            ...prev.data,
            1: {
                ...prev.data[1],
                filters: {
                    ...prev.data[1].filters,
                    [key]: {
                        ...prev.data[1].filters[key],
                        ...data,
                    },
                },
            },
        },
    }))

const resetDuctData = (key) => {
    useStore.setState(prev => ({
        ...prev,
        data: {
            ...prev.data,
            1: {
                ...prev.data[1],
                ducts: {
                    ...prev.data[1].ducts,
                    [key]: null,
                },
            },
        },
    }))
}

const resetFilterData = (key) => {
    useStore.setState(prev => ({
        ...prev,
        data: {
            ...prev.data,
            1: {
                ...prev.data[1],
                filters: {
                    ...prev.data[1].filters,
                    [key]: null,
                },
            },
        },
    }))
}

const setInterlocks = (interlocks: Interlock[]) =>
    useStore.setState(prev => ({
        ...prev,
        install: {
            ...prev.install,
            interlocks,
        },
    }))

const setRelay = (controller: 0 | 1) =>
    (relay: 'relay1' | 'relay2') =>
        (relayData: { equipment?: string, brand?: string, model?: string, normally_closed?: string }) => {
            useStore.setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    1: {
                        ...prev.data[1],
                        controllers: {
                            ...prev.data[1].controllers,
                            [controller]: {
                                ...prev.data[1].controllers[controller],
                                [relay]: {
                                    ...(prev.data[1].controllers[controller]?.[relay] || {}),
                                    ...relayData,
                                },
                            },
                        },
                    },
                },
            }))
        }

const setRelays = (controller: 0 | 1) =>
    (data: { relay1: { equipment: string, brand: string }, relay2: { equipment: string, brand: string } }) =>
        useStore.setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                1: {
                    ...prev.data[1],
                    controllers: {
                        ...prev.data[1].controllers,
                        [controller]: {
                            ...prev.data[1].controllers[controller],
                            ...data,
                        },
                    },
                },
            },
        }))

const selectStep = store => store.step
const selectStepData = store => store.data[store.step]
const selectIsValid = store => store.data[store.step]?.isValid
const selectIsLoading = store => store.data[store.step]?.isLoading as boolean
const selectError = store => store.data[store.step]?.error as string
const selectProceedFnc = store => store.data[store.step]?.proceed
const selectOutputs = (store: InstallStore) => store.install.outputs
const selectEquipments = (store: InstallStore) => store.install.equipment
const selectInterlocks = (store: InstallStore) => store.install.interlocks
const selectFilterData = (stateKey: 'monitor' | 'c1r1' | 'c1r2' | 'c2r1' | 'c2r2') =>
    (store: InstallStore): FilterState => store.data[1].filters[stateKey]
const selectDuctData = (stateKey: 'monitor' | 'c1r1' | 'c1r2' | 'c2r1' | 'c2r2') =>
    (store: InstallStore): DuctState => store.data[1].ducts[stateKey]
const selectMonitorBrand = (store: InstallStore) =>
    store.data[1].monitor.brand
const selectMonitorModel = (store: InstallStore) =>
    store.data[1].monitor.model
const selectMonitorEquipment = (store: InstallStore) =>
    store.data[1].monitor.equipment
const selectRelays = (controller: 0 | 1) => (store: InstallStore) =>
    store.data[1].controllers[controller]

export {
    setStep,
    selectStep,
    setPrevStep,
    setIsValid,
    setStepData,
    setDuctData,
    setFilterData,
    resetDuctData,
    resetFilterData,
    setInterlocks,
    setController,
    setMonitorData,
    setRelay,
    setRelays,
    selectError,
    selectDuctData,
    selectFilterData,
    selectOutputs,
    selectRelays,
    selectIsValid,
    selectStepData,
    selectIsLoading,
    selectProceedFnc,
    selectEquipments,
    selectMonitorBrand,
    selectMonitorModel,
    selectInterlocks,
    selectMonitorEquipment,
    findEquipmentType,
    defaultControllers,
}

export default useStore
